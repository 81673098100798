<script setup lang="ts"></script>

<template>
  <svg
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.59804 29.5619L0.190746 21.8011C-0.063582 21.5113 -0.063582 21.0282 0.190746 20.7706L4.29178 16.5199C4.5779 16.2301 5.05476 16.2301 5.34088 16.5199L7.56625 18.9029C7.85237 19.2249 8.36103 19.1927 8.64715 18.8707L10.7136 16.5199C10.9997 16.1979 11.4765 16.1979 11.7945 16.4877L15.9909 20.7384C16.277 21.0282 16.277 21.4791 15.9909 21.7689L8.67894 29.4975C8.36103 29.8517 7.88416 29.8517 7.59804 29.5619Z"
      fill="white"
    />
    <path
      d="M7.97953 13.8471C11.7627 13.8471 14.8146 10.7556 14.8146 6.92354C14.8146 3.09144 11.7627 0 7.97953 0C4.19641 0 1.14448 3.09144 1.14448 6.92354C1.14448 10.7556 4.19641 13.8471 7.97953 13.8471Z"
      fill="white"
    />
    <path
      d="M20.4098 16.7131H29.8835C30.7737 16.7131 31.4731 17.4216 31.4731 18.3232V27.9196C31.4731 28.8212 30.7737 29.5297 29.8835 29.5297H20.4098C19.5197 29.5297 18.8203 28.8212 18.8203 27.9196V18.3232C18.8203 17.4538 19.5514 16.7131 20.4098 16.7131Z"
      fill="white"
    />
    <path
      d="M31.3459 0.51524C31.3459 0.225418 31.1234 0 30.8372 0L18.8838 0.0322025C18.4388 0.0322025 18.2162 0.611848 18.5341 0.933873L22.1011 4.50191L18.5659 8.08283C18.3116 8.34045 18.3116 8.75908 18.5659 9.0167L22.5716 13.0742C22.8259 13.3318 23.2392 13.3318 23.4935 13.0742L27.0637 9.45788L30.4875 12.881C30.8372 13.203 31.3777 12.9454 31.3777 12.4946L31.3459 0.51524Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
